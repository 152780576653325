import {TableBase} from "../../tables/table_base";

class OldestDownloadsDriver extends TableBase {
  constructor () {
    super('last_card_read_user');
  }

  paintTable ($drivers, last_card_read_user) {
    const driverWarning = moment().subtract(21, 'days');
    const driverError = moment().subtract(28, 'days');

    if (last_card_read_user.length === 0) {
      $drivers.find('tbody').html('<tr><td colspan="2">No drivers to display</td></tr>');
      return;
    }

    for (const driver in last_card_read_user) {
      const id = last_card_read_user[driver].id,
        last_read = last_card_read_user[driver].date === '-' ? moment(0) : moment(last_card_read_user[driver].date);

      const $row = $('<tr>');

      let errorStyle = '';

      if (last_read.isBefore(driverWarning)) {
        if (last_read.isBefore(driverError)) {
          errorStyle = 'infringement';
        } else {
          errorStyle = 'warning';
        }
      }

      const $infoCell = $('<span>').attr({'data-tbl': 'usr', 'data-id': id}).addClass('hover-preview').text(driver);
      const $regCell = $('<td>').append($infoCell);
      const $dataCell = $('<td>').text(last_read.format('ddd Do MMM YYYY')).addClass(errorStyle);

      $row.append($regCell).append($dataCell);
      $drivers.append($row);
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new OldestDownloadsDriver();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
